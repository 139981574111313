import { Dropdown } from '@fluentui/react-northstar'
import React from 'react';


function QueuesFilter(props) {
    return (
        <Dropdown
            items={props.values || []}
            placeholder="Select your Queue"
            checkable
            onChange={(e, selectedOption) => { props.changeFilter(selectedOption.value) }}

        />
    )
}

export default QueuesFilter