import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import "./App.css";
import { connect } from "react-redux";
import { requestEmbedToken } from "./redux/actions";
import { PowerBIEmbed } from "powerbi-client-react";
import {
  models,
  Report,
  Embed,
  IEmbedConfiguration,
  service,
  Page,
} from "powerbi-client";
import QueuesFilter from "./components/QueuesFilter/QueuesComponent";
import AvatarContainer from "./Containers/AvatarContainer";
import { Header, Flex, Button } from "@fluentui/react-northstar";
import { authContext, adalConfig } from "./auth";
import { ArrowLeftIcon } from "@fluentui/react-icons-northstar";

import logo from "./assets/logo192.png";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  withOrientationChange,
} from "react-device-detect";

function App(props) {
  const [filtersValues, setFiltersValues] = useState([""]);
  const [filters, setFiltersObj] = useState({ test: "" });

  const [report, setReport] = useState();
  const activePointRef = React.useRef(report);
  const { isLandscape, isPortrait } = props;
  let layout = models.LayoutType.Master;
  switch (true) {
    case isLandscape === true:
      layout = 3;
      break;
    case isPortrait === true:
      layout = 2;
      break;
    case isBrowser === true:
      layout = 0;
      break;
    default:
      layout = 2;

      break;
  }
  const newSettings = {
    layoutType: layout,
  };

  if (report) {
    report.updateSettings(newSettings);
  }
  const getFilters = (rapport) => {
    rapport
      .getFilters()
      .then((data) => {
        setFiltersObj(data);
        setFiltersValues(data[0].values);
      })
      .catch((e) => {
        console.log("Error when getting filters", e);
      });
  };

  useEffect(() => {
    // if (props.token === undefined) {
    //   props.requestToken();
    // }
  }, []);
  return (
    <div className="App" >
      <div className="grid-container">
        <div className="Filter">
          <Button
            icon={<ArrowLeftIcon />}
            text
            primary
            onClick={() => props.selectReport(null)}
            content="Reports list"
          />
          {/* <QueuesFilter values={props.reportInfos?.tenantQueues} changeFilter={changeFilter} /> */}
        </div>
        <div className="Logo">
          <Flex gap="gap.small">
            <Header as="h5" content={props.reportInfos.name} style={{ fontSize: '16px', margin: '10px' }} />
          </Flex>
        </div>
      </div>
      {props.reportInfos?.id !== null ? (
        <>
          <PowerBIEmbed
            style={{ height: "100%" }}
            embedConfig={{
              type: "report", // Supported types: report, dashboard, tile, visual and qna
              id: props.reportInfos?.id,
              embedUrl: props.reportInfos?.embedUrl,
              accessToken: props.token,
              tokenType: models.TokenType.Embed, //models.TokenType.Aad
              permissions: models.Permissions.All,
              settings: {
                panes: {
                  filters: {
                    expanded: true,
                    visible: false,
                  },
                },
                background: models.BackgroundType.Transparent,
                layoutType: layout,
              },
            }}
            eventHandlers={
              new Map([
                [
                  "loaded",
                  function (event) {
                    getFilters(activePointRef.current);
                  },
                ],
                ["rendered", function () { }],
                ["visualRendered", function () { }],
                ["error", function (event) { }],
              ])
            }
            cssClassName={"report-style-class"}
            getEmbeddedComponent={function (embeddedReport) {
              activePointRef.current = embeddedReport;
              setReport(() => embeddedReport);
            }}
          />
        </>
      ) : null}
    </div>
  );
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    requestToken: () => dispatch(requestEmbedToken()),
  });
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withOrientationChange(App));
