import React, { useState, useEffect } from 'react'
import { authContext, adalConfig } from '../auth'
import { Client } from "@microsoft/microsoft-graph-client";
import { Avatar } from '@fluentui/react-northstar'
import { connect } from 'react-redux'
import { saveUserInfos, saveUserPhoto } from '../redux/actions'
const AvatarContainer = (props) => {
    const [infos, setinfos] = useState(undefined);

    useEffect(() => {

        props.saveUserInfos(authContext.getCachedUser())
        setinfos(props.userInfos)
        // getPersonPhoto(authContext, props)

    }, []);
    return (
        <Avatar
            image={props.userPhoto}
            label=""
            name={`${authContext.getCachedUser().profile.given_name} ${authContext.getCachedUser().profile.family_name}`}
        />
    )
}
const getPersonPhoto = async (authContext, props) => {

    authContext.getCachedUser();
    let token = null;
    const getPhoto = async (token) => {
        const client = Client.init({
            // Use the provided access token to authenticate
            // requests
            authProvider: (done) => {
                done(null, token);
            }
        });
        var photoUrl = "https://graph.microsoft.com/v1.0/me/photo/$value";

        const Avatar = await client
            .api(photoUrl)
            .get();
        props.saveUserPhoto(Avatar)
    }
    await authContext.acquireToken(adalConfig.endpoints.api, async function (errDesc, data, err, tokenType) {
        token = data
        await getPhoto(token)

    })







    return;


}
const mapStateToProps = (state) => ({
        userInfos: state.reportingActions.userInfos,
        userPhoto: state.reportingActions.userPhoto,

})

const mapDispatchToProps = (dispatch) => ({
        saveUserInfos: (userInfos) => dispatch(saveUserInfos(userInfos)),
        saveUserPhoto: (Avatar) => dispatch(saveUserPhoto(Avatar))

    })

export default connect(mapStateToProps, mapDispatchToProps)(AvatarContainer)
