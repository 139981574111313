import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reportingReducers from "./redux/reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import {thunk} from "redux-thunk";
import { Login } from '@microsoft/mgt';
import { authContext } from "./auth";
import { render } from "react-dom";
import {
  Provider as ProviderNorth,
  teamsTheme,
} from "@fluentui/react-northstar";
import { BrowserRouter as Router, Switch,Route } from "react-router-dom";
import Welcome from "./components/WelcomeComponent/WelcomeComponent";
import ReportsListPageContainer from "./Containers/ReportsListPageContainer";
import PrivateRoute from "./Containers/ReportsListPageContainer";
import { IntlProvider } from "react-intl";
import {
  Providers,
  SimpleProvider,
  ProviderState,
} from "@microsoft/mgt";
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...[thunk])
  // other store enhancers if any
);
function getAccessToken(scopes) {
  return new Promise((resolve, reject) => {
    authContext.acquireToken(
      "https://graph.microsoft.com",
      function (errDesc, token, err, tokenType) {
        if (token) {
          Providers.globalProvider.setState(ProviderState.SignedIn);
          resolve(token);
        }
      }
    );
  });
}
function login() {
  authContext.handleWindowCallback()
  authContext.login().then(() => {
    Providers.globalProvider.setState(ProviderState.SignedIn);
  });
}
function logout() {
  authContext.logOut();
}
let provider = new SimpleProvider(getAccessToken, login, logout);
Providers.globalProvider = provider;
authContext.handleWindowCallback();
let user = authContext.getCachedUser();
if (user) {
  Providers.globalProvider.setState(ProviderState.SignedIn);
}
const store = createStore(
  reportingReducers,
  composeWithDevTools(applyMiddleware(thunk))
);
render(
  <Router>
      <IntlProvider locale="en">
        <ProviderNorth theme={teamsTheme}>
        <Provider store={store}>
          <Switch>
          <Route
              exact
            path="/ReportsList"
              component={ReportsListPageContainer}
          ></Route>
          <Route path="/" render={(props) => <Welcome />} />
          </Switch>
          </Provider>
        </ProviderNorth>
    </IntlProvider>
  </Router>,
  document.getElementById("root")
);