import React, { useEffect, useState } from "react";
import { getReportsList } from "../redux/actions";
import App from "../App";
import ReportsListComponent from "../components/reportsListComponent/reportsListComponent";
import { connect } from "react-redux";
import { Header, Loader, Flex, Layout } from "@fluentui/react-northstar";
import logo from "../assets/heedify-logo.png";
const ReportsListPageContainer = (props) => {
  const [selectedReport, setSelectedReport] = useState(null);
  const [reportsLoading, setReportsLoading] = useState(false);
  function GetLogo() {
    return (
      <div className="welcome-logo">
        <img
          alt="Heedify"
          src={logo}
        ></img>
      </div>
    );
  }
  useEffect(() => {
    props.getReportsList();
  }, []);
  useEffect(() => {
    props.reportList ? setReportsLoading(false) : setReportsLoading(true);
  }, [props.reportList]);
  const selectReport = (data) => {
    setSelectedReport(data);
  };

  return (
    <> 
      <Layout
        className="header"
        start={GetLogo()}
        main="Heedify reports portal"
        end={<mgt-login></mgt-login>}
      />
      {!selectedReport ? (
        <Flex column style={{ height: "100%", display: "grid" }}>
          <div style={{ backgroundColor: "#f5f5f5" }}>
            <Header as="h2" content="Reports list" style={{ margin: "10px" }} />
            {reportsLoading ? (
              <Loader label="fetching reports" />
            ) : (
              <div style={{ margin: "10px" }}>
                <ReportsListComponent
                  selectReport={selectReport}
                  reportsListData={props.reportList}
                />
              </div>
            )}
          </div>
        </Flex>
      ) : (
        <>
          {props.reportList ? (
            <App
              selectReport={selectReport}
              reportInfos={selectedReport.reportInfos}
              token={selectedReport.token}
            />
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) =>({
  reportList: state.reportingActions.listOfReports,
})
const mapDispatchToProps = (dispatch) => ({
  getReportsList: () => dispatch(getReportsList()),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsListPageContainer);
