import React from "react";
import { Button, Flex, Image, Text, Card } from "@fluentui/react-northstar";

const ReportsListComponent = (props) => {
  return (
    <div id="reports-list">
      {props.reportsListData?.map((report, k) => (
        <p key={k} onClick={() => props.selectReport(report)}>{report.reportInfos.name}</p>
      ))}
    </div>
  );
};
export default ReportsListComponent;
