import * as React from "react"

function Illustration(props) {
    return (
        <svg data-name="Layer 1" viewBox="0 0 407.17 365.75" width="100%" height="100%" {...props}>
            <defs>
                <linearGradient
                    id="prefix__a"
                    x1={45.84}
                    y1={4.24}
                    x2={664.15}
                    y2={559.48}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0} stopOpacity={0} />
                    <stop offset={0.99} />
                </linearGradient>
                <linearGradient
                    id="prefix__d"
                    x1={103.8}
                    y1={330.27}
                    x2={105.04}
                    y2={312.42}
                    xlinkHref="#prefix__a"
                />
                <linearGradient
                    id="prefix__e"
                    x1={188.11}
                    y1={321.3}
                    x2={175.97}
                    y2={309.89}
                    xlinkHref="#prefix__a"
                />
                <linearGradient
                    id="prefix__b"
                    x1={111.06}
                    y1={252.75}
                    x2={119.24}
                    y2={252.75}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0} stopColor="#e5c5d5" />
                    <stop offset={0.42} stopColor="#ead4d1" />
                    <stop offset={1} stopColor="#f0e9cb" />
                </linearGradient>
                <linearGradient
                    id="prefix__f"
                    x1={144.2}
                    y1={280.25}
                    x2={155.89}
                    y2={280.25}
                    xlinkHref="#prefix__b"
                />
                <linearGradient
                    id="prefix__g"
                    x1={157.75}
                    y1={281.81}
                    x2={142.38}
                    y2={318}
                    xlinkHref="#prefix__a"
                />
                <linearGradient
                    id="prefix__c"
                    x1={231.55}
                    y1={300.77}
                    x2={228.57}
                    y2={-21.25}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.02} stopColor="#fff" stopOpacity={0} />
                    <stop offset={0.32} stopColor="#fff" stopOpacity={0.39} />
                    <stop offset={0.68} stopColor="#fff" stopOpacity={0.68} />
                    <stop offset={1} stopColor="#fff" />
                </linearGradient>
                <linearGradient
                    id="prefix__h"
                    x1={161.62}
                    y1={254.15}
                    x2={112.17}
                    y2={231.6}
                    xlinkHref="#prefix__a"
                />
                <linearGradient
                    id="prefix__i"
                    x1={110.26}
                    y1={243.72}
                    x2={124.09}
                    y2={243.72}
                    xlinkHref="#prefix__b"
                />
                <linearGradient
                    id="prefix__j"
                    x1={146.81}
                    y1={259.45}
                    x2={83.04}
                    y2={251.49}
                    xlinkHref="#prefix__a"
                />
                <linearGradient
                    id="prefix__k"
                    x1={115.28}
                    y1={302.46}
                    x2={128.17}
                    y2={247.92}
                    xlinkHref="#prefix__a"
                />
                <linearGradient
                    id="prefix__l"
                    x1={140.82}
                    y1={240.98}
                    x2={150.74}
                    y2={240.98}
                    xlinkHref="#prefix__b"
                />
                <linearGradient
                    id="prefix__m"
                    x1={156.28}
                    y1={253.26}
                    x2={154.54}
                    y2={63.88}
                    xlinkHref="#prefix__c"
                />
                <linearGradient
                    id="prefix__n"
                    x1={187.86}
                    y1={248.36}
                    x2={186.5}
                    y2={101.05}
                    xlinkHref="#prefix__c"
                />
                <linearGradient
                    id="prefix__o"
                    x1={219.75}
                    y1={253.26}
                    x2={218}
                    y2={63.88}
                    xlinkHref="#prefix__c"
                />
                <linearGradient
                    id="prefix__p"
                    x1={305.94}
                    y1={68.91}
                    x2={306.93}
                    y2={269.21}
                    xlinkHref="#prefix__c"
                />
                <linearGradient
                    id="prefix__q"
                    x1={328.33}
                    y1={101.23}
                    x2={318.83}
                    y2={177.95}
                    xlinkHref="#prefix__c"
                />
                <linearGradient
                    id="prefix__r"
                    x1={366.86}
                    y1={66.58}
                    x2={364.63}
                    y2={158.06}
                    xlinkHref="#prefix__c"
                />
                <linearGradient
                    id="prefix__s"
                    x1={367.48}
                    y1={138.01}
                    x2={364.5}
                    y2={248.08}
                    xlinkHref="#prefix__c"
                />
                <linearGradient
                    id="prefix__t"
                    x1={80.31}
                    y1={133.22}
                    x2={79.32}
                    y2={188.09}
                    xlinkHref="#prefix__c"
                />
            </defs>
            <title>{"Analyze Report 1"}</title>
            <path
                d="M127.6 29.87c-7.78 11.34-11.89 24.71-17.36 37.32a182 182 0 01-52 68.49c-15.29 12.43-32.92 22.68-44.37 38.74-23.19 32.53-13.81 77.91 2.74 114.29 7.05 15.48 15.4 30.69 27.54 42.59 21.62 21.17 53.4 29.61 83.62 28.05 30.43-1.57 60-12.3 90.5-12 32.27.34 63.09 13 95.08 17.27s69.63-3 85.82-30.91c11.62-20.06 8.91-45.33 2.39-67.58s-16.54-43.75-18.61-66.84c-3.34-35.92 12.79-72.58 4.07-107.58-3.66-14.66-11.54-28-21.07-39.73-24-29.33-59.9-49.21-97.7-51.68-13.62-.89-27.3.41-40.87 1.69C195.76 5 148.04.09 127.6 29.87z"
                fill="#2584d5"
                opacity={0.18}
                style={{
                    isolation: "isolate",
                }}
            />
            <rect
                x={120.57}
                y={80.6}
                width={225.92}
                height={184.37}
                rx={13.75}
                fill="url(#prefix__a)"
            />
            <path fill="#2584d5" d="M103.32 311.3h2.16v20.77h-2.16z" />
            <path fill="url(#prefix__d)" d="M103.32 311.3h2.16v20.77h-2.16z" />
            <path
                d="M145.43 292.37a33.12 33.12 0 018.71 6.42c7.78 7.48 14.34 16.51 17.45 26.85a2.16 2.16 0 010 1.69 1.93 1.93 0 01-.7.66 13.79 13.79 0 01-3.06 1.5l-16.47-17c-2.13-2.2-4.3-4.46-5.56-7.24s-1.62-6.3-3.62-8.76c-.86-1.05-2-1.86-2.82-2.92-1.09-1.37-1.62-3.12.54-3.09 1.73.05 4.05 1.23 5.53 1.89z"
                fill="#3e3e54"
            />
            <path
                d="M166.64 294a32.77 32.77 0 018.71 6.43c7.79 7.47 14.34 16.5 17.46 26.84a2.25 2.25 0 010 1.69 2.07 2.07 0 01-.71.67 13.76 13.76 0 01-3.06 1.49l-16.46-17c-2.13-2.2-4.31-4.45-5.57-7.24s-1.61-6.3-3.62-8.76c-.85-1.05-2-1.86-2.82-2.92-1.08-1.37-1.61-3.12.54-3.09 1.73.04 4.02 1.17 5.53 1.89z"
                transform="translate(-21.05 -1.29)"
                fill="url(#prefix__e)"
            />
            <path
                d="M72.86 276.46c-1.65-6.57-3.81-11.52-3.17-17.49a4 4 0 01.48-1.74 3.59 3.59 0 012.89-1.35 13.49 13.49 0 018.33 2.45 29.39 29.39 0 016.32 6.13c5.9 7.29 10.39 15.77 17.17 22.25 1.32 1.27 2.88 2.52 4.71 2.61 5.17.27 10.41 1.45 15.58 1.72 2.65.15 5.42.32 7.69 1.7s5.77 4.59 4.62 7c-.77 1.59-4.43 2.27-6 3.06l-6.43 3.24a44.45 44.45 0 01-8.55 3.58 38 38 0 01-12.5.63c-3.78-.29-7.64-.78-11-2.49-5.55-2.8-9-8.48-11.5-14.19s-7.13-11.08-8.64-17.11z"
                fill="#3e3e54"
            />
            <path
                d="M111.07 255.12a3.57 3.57 0 00.12 1.22 2.73 2.73 0 00.73 1.05 6.68 6.68 0 006.22 1.66 1.56 1.56 0 00.93-.53 1.29 1.29 0 000-1.29c-.19-.4-.5-.75-.72-1.14-.88-1.57-.26-3.53-.51-5.31a5.53 5.53 0 00-2.42-3.81c-1-.67-2.87-1.18-3.83-.09-.7.79-.49 2.17-.46 3.14.04 1.7-.02 3.4-.06 5.1z"
                transform="translate(-21.05 -1.29)"
                fill="url(#prefix__b)"
            />
            <path
                d="M148.7 277.33a10.06 10.06 0 013 .21 6.65 6.65 0 012 .65 6.74 6.74 0 01.82.58 4.19 4.19 0 011.26 1.49 1.74 1.74 0 01-.21 1.84.92.92 0 01-.53.3 1.61 1.61 0 01-1-.33 4.36 4.36 0 00-2.09-.51 1.25 1.25 0 00-.66.14c-.31.18-.44.56-.67.84a2.32 2.32 0 01-1.85.67 5 5 0 01-2.82-.89 4.21 4.21 0 01-1.67-2.42c-.24-1 .61-1.23 1.34-1.57a9.82 9.82 0 013.08-1z"
                transform="translate(-21.05 -1.29)"
                fill="url(#prefix__f)"
            />
            <path
                d="M140.33 299.81c-.82-.13-18.95 6.49-22.44 7.95a23.07 23.07 0 01-11 2 16.38 16.38 0 01-5.14-1.37 8.09 8.09 0 01-2.54-1.64 9.86 9.86 0 01-2.15-4.41 11.75 11.75 0 01-.56-5.11c3.72.07 7.44-1.22 10.91-2.56 13.1-5.05 26.94-9.75 40.88-8.13a8.1 8.1 0 013.69 1.1 6.07 6.07 0 012.33 5 20 20 0 01-1.1 5.64 107 107 0 00-4.14 30.43 13.45 13.45 0 01-4.42.41 2.14 2.14 0 01-1.29-.42 2.18 2.18 0 01-.54-1.54c-.87-10.32-2.37-16.69-2.49-27.35z"
                fill="#3e3e54"
            />
            <path
                d="M143 335.28a1.17 1.17 0 00.24.69 1.31 1.31 0 00.77.32 58.07 58.07 0 0015.58.38 2.2 2.2 0 00-.75-1.73 6.59 6.59 0 00-1.63-1.05l-6.26-3.18a3.09 3.09 0 01-1.34-1c-.25-.42-.31-1-.71-1.28a1.59 1.59 0 00-.8-.24c-1.31-.17-3.62-.46-4.47.85-1.07 1.48-.85 4.49-.63 6.24zM169.1 333.04a2.07 2.07 0 00.72.72 2.55 2.55 0 001.2.17l11.53-.2a2.71 2.71 0 001.06-.16.93.93 0 00.58-.83 1.22 1.22 0 00-.44-.75c-2.86-2.79-7.52-2.54-11-4.55a8.34 8.34 0 00-1.72-.91c-3.97-1.21-3.18 4.59-1.93 6.51z"
                fill="#3e3e54"
            />
            <path fill="#2584d5" d="M129.83 283.17h25.54v2.38h-25.54z" />
            <path
                d="M155.95 278.31a27.84 27.84 0 01-1.47 2.39 7.6 7.6 0 00-1.15 1.88 1.27 1.27 0 00.36 1.55c.57.29 1.23-.19 1.63-.68a14.9 14.9 0 001.38-2c1.54-2.58 2.92-5.26 4.3-7.94l3.89-7.57a.45.45 0 00.08-.31.43.43 0 00-.19-.23c-.72-.48-1.42-.5-1.86.23-.6 1-1.1 2.06-1.65 3.08q-1.67 3.09-3.38 6.17zM69.55 262.99s10.84 41.61 32.15 45.39c0 0 15.69.17 29.77-5.6 10-4.11 2.36.95 2.36.95s-22.07 15.15-36.79 9.3c-11.71-4.65-19.48-2.43-27.49-50.04z"
                fill="#2584d5"
            />
            <path
                d="M161.55 301.1c-.83-.13-19 6.49-22.44 7.95a23.11 23.11 0 01-11.06 2 16.45 16.45 0 01-5.14-1.37 8.16 8.16 0 01-2.53-1.64 10 10 0 01-2.16-4.41 11.9 11.9 0 01-.56-5.11c3.72.07 7.45-1.22 10.92-2.56 13.1-5.05 26.94-9.75 40.88-8.13a8.1 8.1 0 013.69 1.1 6.1 6.1 0 012.33 5 20.36 20.36 0 01-1.1 5.64 106.32 106.32 0 00-4.09 30.43 13.49 13.49 0 01-4.43.41 2.11 2.11 0 01-1.28-.42 2.24 2.24 0 01-.55-1.54c-.85-10.32-2.36-16.69-2.48-27.35z"
                transform="translate(-21.05 -1.29)"
                fill="url(#prefix__g)"
            />
            <rect
                x={94.97}
                y={327.96}
                width={17.96}
                height={6.92}
                rx={3.46}
                fill="#2584d5"
            />
            <rect
                x={117.38}
                y={78.02}
                width={225.92}
                height={184.37}
                rx={13.75}
                fill="#2584d5"
            />
            <rect
                x={117.38}
                y={78.02}
                width={225.92}
                height={184.37}
                rx={13.75}
                fill="#2584d5"
            />
            <rect
                x={117.38}
                y={78.02}
                width={225.92}
                height={184.37}
                rx={13.75}
                fill="url(#prefix__c)"
            />
            <path
                d="M145.55 250.2a9.74 9.74 0 012.75-1.11c1.51-.49 3.07-1.52 3.27-3.09s-1.18-3.29-.74-4.9a10.56 10.56 0 011-1.84 1.76 1.76 0 000-2 1.49 1.49 0 00-1.63-.24 4.54 4.54 0 00-1.36 1.08l-7.26 7.49a12.28 12.28 0 00-2 2.44 7.9 7.9 0 00-.86 4.32c0 1 .15 3.89 1.21 4.31 2.07.88 4.26-5.45 5.62-6.46z"
                transform="translate(-21.05 -1.29)"
                fill="url(#prefix__h)"
            />
            <path
                d="M110.26 243.9c.95 3.53 3.94 6.2 7.26 7.73a7.44 7.44 0 003.08.82 3.34 3.34 0 002.78-1.35 5.06 5.06 0 00.58-2.78l.12-6.08a10.23 10.23 0 00-.32-3.45 5.91 5.91 0 00-5.92-3.79 7.49 7.49 0 00-5.94 4.11 10.28 10.28 0 00-.6 7.36"
                transform="translate(-21.05 -1.29)"
                fill="url(#prefix__i)"
            />
            <path
                d="M88.39 233.06a5.69 5.69 0 001.6-.79c.27-.25.46-.59.71-.86a4.23 4.23 0 012.74-1c3.34-.35 6.81-.5 10 .62a3.64 3.64 0 012.34 1.85 3.2 3.2 0 01.16 1.27 4.92 4.92 0 01-1 2.8 5.68 5.68 0 01-4 1.85 11.94 11.94 0 00-1.7.17 2 2 0 00-1.34 1c-.35.73 0 1.62-.3 2.38a.71.71 0 01-.16.26c-.23.21-.62.08-.84-.15s-.34-.55-.56-.77a1.36 1.36 0 00-1.78 0 1.92 1.92 0 00-.59 1.77 3.42 3.42 0 00.89 1.7.4.4 0 01.14.26.39.39 0 01-.1.19c-.42.52-1.12.74-1.55 1.26s-.49 1.09-1 1.44a2.28 2.28 0 01-1.42.28 2.49 2.49 0 01-1-.2 2.35 2.35 0 01-1-1.64c-.76-3-1-6.1-2.2-8.94-.75-1.68.43-4.03 1.96-4.75z"
                fill="#3e3e54"
            />
            <path
                d="M123.27 246.1a2.64 2.64 0 01-1 1.48l-3.51 3.18-16.21 14.64a11 11 0 00-2.29-3c-3-2.95-6.93-5.28-3-7.61 1.3-.76 18.44-10.74 22.22-13.29a1.31 1.31 0 011.91.47l1.41 2.63a2.62 2.62 0 01.47 1.5z"
                fill="#2584d5"
            />
            <path
                d="M140.44 251.22l-.64.74-16.21 14.18a10.75 10.75 0 00-2.29-2.9c.53-3.39 4.32-5 6.9-7.14 3.3-2.77 6.7-5.7 11-6.93.62-.18 1.43-.27 1.79.26s-.11 1.27-.55 1.79z"
                transform="translate(-21.05 -1.29)"
                fill="url(#prefix__j)"
            />
            <path
                d="M111.78 289.81a10.23 10.23 0 01-7.46 8 12.37 12.37 0 01-12.26-4.55 20.27 20.27 0 01-3.24-6.55c-3-9.4-5-21.31-1-30.35.73-1.66 2.71-2.36 4.52-2.59a9.79 9.79 0 0111.5 7.73 32.62 32.62 0 01.9 8.08 18.56 18.56 0 003 9.32c.33.53.69 1.05 1 1.58 1.46 2.23 2.87 4.53 3.14 7.15a7.6 7.6 0 01-.1 2.18z"
                fill="#2584d5"
            />
            <path
                d="M132.83 291.1a63.56 63.56 0 01-7.92.5 28.13 28.13 0 01-7.72-.75 11.86 11.86 0 01-6.46-4.08c-2.66-3.52-2.43-8.34-2.55-12.75-.07-2.36-.26-4.84.76-7a6.79 6.79 0 014.39-3.61 7.63 7.63 0 015.08.56c1.34.68 1.51 2.08 1.75 3.46.7 4.11.13 8.36 1.21 12.42a2.58 2.58 0 00.63 1.28 2.88 2.88 0 001.92.54c1.94.06 3.88.1 5.83.13 1.46 2.23 2.87 4.53 3.14 7.15a7.6 7.6 0 01-.06 2.15z"
                transform="translate(-21.05 -1.29)"
                fill="url(#prefix__k)"
            />
            <path
                d="M100.32 274.61a2.58 2.58 0 00.63 1.28 2.84 2.84 0 001.92.54q10.12.33 20.26 0a1.48 1.48 0 01.78.12 1.33 1.33 0 01.41.44 4.08 4.08 0 01-.37 4.72 7 7 0 01-2.76 1.74c-5.45 2.24-11.44 2.86-17.34 2.91a28.52 28.52 0 01-7.72-.75 11.9 11.9 0 01-6.46-4.09c-2.66-3.52-2.42-8.33-2.55-12.74-.07-2.37-.25-4.85.76-7a6.76 6.76 0 014.39-3.61 7.69 7.69 0 015.08.56c1.34.68 1.52 2.08 1.75 3.47.71 4.11.14 8.36 1.22 12.41z"
                fill="#2584d5"
            />
            <path
                d="M144 239.1a26.3 26.3 0 015.69-3.38.9.9 0 01.72-.13.62.62 0 01.34.59c0 1-1.4 1.44-1.86 2.36s0 1.83.16 2.76a4.25 4.25 0 01-1.38 3.55c-1.44 1.35-5.18 2.52-6.52.38s1.46-4.96 2.85-6.13z"
                transform="translate(-21.05 -1.29)"
                fill="url(#prefix__l)"
            />
            <path fill="url(#prefix__m)" d="M145.91 121.81h19.34v109.32h-19.34z" />
            <path fill="url(#prefix__n)" d="M177.64 146.13h19.34v85h-19.34z" />
            <path fill="url(#prefix__o)" d="M209.37 121.81h19.34v109.32h-19.34z" />
            <path
                d="M344 161.65a38.77 38.77 0 01-.25 4.34 37.56 37.56 0 11-41.65-41.65 39.09 39.09 0 014.35-.25A37.56 37.56 0 01344 161.65z"
                transform="translate(-21.05 -1.29)"
                fill="url(#prefix__p)"
            />
            <path
                d="M344 161.65a38.77 38.77 0 01-.25 4.34h-41.69v-41.65a39.09 39.09 0 014.35-.25A37.56 37.56 0 01344 161.65z"
                transform="translate(-21.05 -1.29)"
                fill="url(#prefix__q)"
            />
            <path
                fill="none"
                stroke="#2584d5"
                strokeMiterlimit={10}
                strokeWidth={0.88}
                d="M297.89 141.47l13.37-23.45h41.08M276.06 179.82l27.14 27.15h55.41M154.91 173.62l-16.52-16.52H79.88"
            />
            <rect
                x={349.6}
                y={104.87}
                width={32.06}
                height={24.46}
                rx={6.78}
                fill="#2584d5"
            />
            <rect
                x={349.6}
                y={194.12}
                width={32.06}
                height={24.46}
                rx={6.78}
                fill="#2584d5"
            />
            <rect
                x={63.85}
                y={144.87}
                width={32.06}
                height={24.46}
                rx={6.78}
                fill="#2584d5"
            />
            <rect
                x={349.6}
                y={104.87}
                width={32.06}
                height={24.46}
                rx={6.78}
                fill="url(#prefix__r)"
            />
            <rect
                x={349.6}
                y={194.12}
                width={32.06}
                height={24.46}
                rx={6.78}
                fill="url(#prefix__s)"
            />
            <rect
                x={63.85}
                y={144.87}
                width={32.06}
                height={24.46}
                rx={6.78}
                fill="url(#prefix__t)"
            />
        </svg>
    )
}

export default Illustration
